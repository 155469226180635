<template>
  <router-link :to="{name: 'home'}" class='logo'>
    <img :src="src" alt="Rbns">
  </router-link>
</template>

<script type="text/javascript">

export default {
  name: 'Logo',

  props: {
    full: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      src: require(this.full ? '@/assets/images/logo-full.webp' : '@/assets/images/logo_3.png')
    }
  }
}

</script>

<style lang="scss" scoped>
  img {
    max-width: 100%;
  }
</style>
