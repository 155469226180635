import Vue from 'vue'

const round5 = (x) => {
  return (x % 5) >= 2.5 ? parseInt(x / 5) * 5 + 5 : parseInt(x / 5) * 5
}

Vue.filter('round5', (value) => {
  value = parseInt(value)
  if (!value) return value
  return round5(value)
})
