import Vue from 'vue'

const unitsLocal = {
  he: {
    m: 'מ״ר',
    ft: 'ר״ר',
    in: 'א״ר'
  },
  en: {
    m: 'm2',
    ft: 'sq. ft',
    in: 'aq. in'
  }
}

var filter = (text, units = 'm') => {
  if (!text) return ''

  const dir = Vue.prototype.$config.locale.dir
  const lang = Vue.prototype.$config.locale.lang

  if (dir === 'rtl') {
    return `${text} ${unitsLocal[lang][units]}`
  } else {
    return `${unitsLocal[lang][units]} ${text}`
  }
}

Vue.filter('size', filter)
