/*
  this service use as enrty point to google maps api
  all exported method here return a Promise so must be used
  within an async methods and await when calling this api.

  * the Promises are for handling calles to this service before the google map api loaded
*/

import mapLoader from '@/plugins/google-maps'

export default (config) => {
  const loader = mapLoader(config)

  var _Maps = null

  var preLoadCollector = {
    geocoders: [],
    bounds: [],
    maps: [],
    streetViews: [],
    streetViewPanoramas: [],
    autocompletes: [],
    addListeners: []
  }

  // PRELOADED METHODS (run the Promises that called before google loaded)
  const runPreloadedMaps = () => {
    preLoadCollector.maps.forEach((m) => {
      // 0 - resolve, 1 - func, 2 - el, 3 - options
      m[0](m[1](m[2], m[3]))
    })
  }

  const runPreloadedEvents = () => {
    preLoadCollector.addListeners.forEach((e) => {
      // 0 - resolve, 1 - func, 2 - el, 3 - options, 4 - callback
      e[0](e[1](e[2], e[3], e[4]))
    })
  }

  const runPreloadedAutocompletes = () => {
    preLoadCollector.autocompletes.forEach((m) => {
      // 0 - resolve, 1 - func, 2 - el
      m[0](m[1](m[2]))
    })
  }

  const runPreloadedGeocoders = () => {
    preLoadCollector.geocoders.forEach((g) => {
      g[0](g[1]())
    })
  }

  const runPreloadedStreetViews = () => {
    preLoadCollector.streetViews.forEach((sv) => {
      sv[0](sv[1]())
    })
  }

  const runPreloadedStreetViewPanoramas = () => {
    preLoadCollector.streetViewPanoramas.forEach((svp) => {
      svp[0](svp[1](svp[2]))
    })
  }

  const runPreloadedBounds = () => {
    preLoadCollector.bounds.forEach((b) => {
      // 0 - resolve, 1 - func
      b[0](b[1])
    })
  }

  // SERVICE METHODS (expordet methods)
  const addListener = (obj, _event, callback = () => {}) => {
    return new Promise((resolve) => {
      if (_Maps) {
        resolve(_Maps.event.addListener(obj, _event, callback))
      } else {
        var fn = (a, b, c) => {
          return _Maps.event.addListener(a, b, c)
        }
        preLoadCollector.addListeners.push([resolve, fn, obj, _event, callback])
      }
    })
  }

  const Geocoder = () => {
    return new Promise((resolve) => {
      if (_Maps) {
        resolve(new _Maps.Geocoder())
      } else {
        var fn = () => {
          return new _Maps.Geocoder()
        }
        preLoadCollector.maps.push([resolve, fn])
      }
    })
  }

  const StreetViewService = () => {
    return new Promise((resolve) => {
      if (_Maps) {
        resolve(new _Maps.StreetViewService())
      } else {
        var fn = () => {
          return new _Maps.StreetViewService()
        }
        preLoadCollector.streetViews.push([resolve, fn])
      }
    })
  }

  const StreetViewPanorama = (el) => {
    return new Promise((resolve) => {
      if (_Maps) {
        resolve(new _Maps.StreetViewPanorama(el))
      } else {
        var fn = (a) => {
          return new _Maps.StreetViewPanorama(a)
        }
        preLoadCollector.streetViewPanoramas.push([resolve, fn, el])
      }
    })
  }

  // this function must run after map has loaded (the map is a required param in the options)
  const Marker = (options) => {
    return new _Maps.Marker(options)
  }

  // this function must run after map has loaded (the map is a required param in the options)
  const InfoWindow = (options) => {
    return new _Maps.InfoWindow(options)
  }

  const LatLngBounds = () => {
    return new Promise((resolve) => {
      if (_Maps) {
        resolve(new _Maps.LatLngBounds())
      } else {
        var fn = () => {
          return new _Maps.LatLngBounds()
        }
        preLoadCollector.bounds.push([resolve, fn])
      }
    })
  }

  const Autocomplete = (el) => {
    return new Promise((resolve) => {
      if (_Maps) {
        resolve(new _Maps.places.Autocomplete(el))
      } else {
        var fn = (a) => {
          return new _Maps.places.Autocomplete(a)
        }
        preLoadCollector.autocompletes.push([resolve, fn, el])
      }
    })
  }

  const Maps = (el, options = {}) => {
    return new Promise((resolve) => {
      if (_Maps) {
        resolve(new _Maps.Map(el, options))
      } else {
        var fn = (a, b) => {
          return new _Maps.Map(a, b)
        }
        preLoadCollector.maps.push([resolve, fn, el, options])
      }
    })
  }

  const Animation = (animation) => {
    if (_Maps) {
      return _Maps.Animation[animation]
    }
  }

  const getAddressByLocation = (locations = []) => {
    if (!_Maps) {
      console.error('$maps.getAddressByLocation - Maps not loaded yet')
      return false
    }

    // TODO: not tested yet (Object or Array of Objects)
    return new Promise((resolve) => {
      const geo = new _Maps.Geocoder()
      geo.geocode({ location: locations }, (results, status) => {
        if (status === 'OK') {
          var resultsLocation = []
          var location, lat, lng
          results.forEach((result) => {
            location = result.geometry.location
            lat = location.lat()
            lng = location.lng()

            resultsLocation.push({
              cords: {
                lat,
                lng
              },
              address: result.formatted_address
            })
          })
          resolve(resultsLocation)
        } else {
          resolve(status)
        }
      })
    })
  }

  const getLocationByAddress = (addresses = '') => {
    if (!_Maps) {
      console.error('$maps.getLocationByAddress - Maps not loaded yet')
      return false
    }

    return new Promise((resolve) => {
      const geo = new _Maps.Geocoder()
      geo.geocode({ address: addresses.toString() }, (results, status) => {
        if (status === 'OK') {
          var resultsLocation = []
          var location, lat, lng
          results.forEach((result) => {
            location = result.geometry.location
            lat = location.lat()
            lng = location.lng()

            resultsLocation.push({
              cords: {
                lat,
                lng
              },
              address: result.formatted_address
            })
          })
          resolve(resultsLocation)
        } else {
          resolve(status)
        }
      })
    })
  }

  loader.load().then(() => {
    _Maps = window.google.maps
    runPreloadedMaps()
    runPreloadedGeocoders()
    runPreloadedBounds()
    runPreloadedStreetViews()
    runPreloadedStreetViewPanoramas()
    runPreloadedAutocompletes()
    runPreloadedEvents()
  })

  return {
    Geocoder,
    Marker,
    InfoWindow,
    LatLngBounds,
    Maps,
    Animation,
    StreetViewService,
    StreetViewPanorama,
    Autocomplete,
    addListener,
    getLocationByAddress,
    getAddressByLocation
  }
}
