<template>
  <div :style="style"/>
</template>

<script type="text/javascript">
export default {
  name: 'Spacer',
  props: {
    width: {
      type: String,
      required: false
    },
    height: {
      type: String,
      required: false
    }
  },
  data () {
    return {
      style: ' width: ' + this.width + '; height: ' + this.height + ';'
    }
  }
}
</script>
