// import Config from '@/config/app-config.js'
import Vue from 'vue'
import VueI18n from 'vue-i18n'
import translations from '@/config/locales/translations'

Vue.use(VueI18n)

const messages = translations

const i18n = (config) => {
  const vuei18n = new VueI18n({
    locale: config.locale.lang,
    fallbackLocale: 'en',
    messages
  })

  return vuei18n
}

export default i18n
