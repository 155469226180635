export default {
  supportEmail: 'support@rbns.co.il',
  accessibilityEmail: 'accessibility@rbns.co.il',
  accessibilityName: 'יוסף כהן',
  mainPhone: '0774110137',
  shortPhone: '*2305',
  whatsappPhone: '0502927279',
  whatsappChatBot: 'https://api.whatsapp.com/send?phone=972549026366&text=',
  facebook: 'https://www.facebook.com/Rbns-סטרטאפ-לשיווק-נדלן-1966997170285729',
  instagram: 'https://www.instagram.com/rbnsapp/',
  youtube: 'https://www.youtube.com/@rbnsapp',
  googleApiKey: 'AIzaSyDSlWgunTfexyS8dsr5OUVmvUDG0ZkU5i8',
  recaptchaSiteKey: '6Lf_y7IZAAAAAFTGeb4EViw6MbGbTZEpVEZXJ7VM',
  mixpanelToken: process.env.NODE_ENV !== 'production' ? '93f572775feac500b9e1d8d6da38dea1' : '8bd2182ce4e2471c7aa6ed13956a38e9',
  twitter: '',
  linkedin: '',
  address: ''
}
