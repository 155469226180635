<template>
  <svg height="800px" width="800px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 503.893 503.893" xml:space="preserve">
    <g transform="translate(1 1)">
      <path style="fill:#ECF4F7;" d="M20.547,464.493V174.36c0-17.067,17.067-34.133,34.133-34.133h136.533v34.133H80.28
        c-8.533,0-25.6,8.533-25.6,17.067V430.36h76.8h238.933h76.8V191.427c0-8.533-8.533-17.067-17.067-17.067H310.68v-34.133h136.533
        c17.067,0,34.133,17.067,34.133,34.133v256h17.067v34.133c0,17.067-17.067,34.133-34.133,34.133H37.613
        c-17.067,0-34.133-17.067-34.133-34.133H20.547z M396.013,396.227c0,0,0,25.6-25.6,34.133H131.48
        c-25.6-8.533-25.6-34.133-25.6-34.133c0-18.773,15.36-34.133,34.133-34.133c6.827,0,11.947,1.707,17.067,5.12
        c1.707-12.8,11.947-22.187,25.6-22.187s23.893,10.24,25.6,23.04c9.387-13.653,24.747-23.04,42.667-23.04
        c17.92,0,34.133,9.387,42.667,23.04c0.853-12.8,11.947-23.04,25.6-23.04c12.8,0,23.893,9.387,25.6,22.187
        c5.12-3.413,11.093-5.12,17.067-5.12C380.653,362.093,396.013,377.453,396.013,396.227z"/>
      <path style="fill:#7EE1E6;" d="M191.213,293.827h25.6h34.133h59.733h34.133V276.76c0-51.2-34.133-51.2-34.133-85.333V174.36
        h119.467c8.533,0,17.067,8.533,17.067,17.067V430.36h-76.8c25.6-8.533,25.6-34.133,25.6-34.133
        c0-18.773-15.36-34.133-34.133-34.133c-6.827,0-11.947,1.707-17.067,5.12c-1.707-12.8-11.947-22.187-25.6-22.187
        c-13.653,0-23.893,10.24-25.6,23.04c-9.387-13.653-24.747-23.04-42.667-23.04c-17.92,0-34.133,9.387-42.667,23.04
        c-0.853-12.8-11.947-23.04-25.6-23.04c-12.8,0-23.893,9.387-25.6,22.187c-5.12-3.413-11.093-5.12-17.067-5.12
        c-18.773,0-34.133,15.36-34.133,34.133c0,0,0,25.6,25.6,34.133h-76.8V191.427c0-8.533,17.067-17.067,25.6-17.067h110.933v17.067
        c0,34.133-34.133,34.133-34.133,85.333v17.067H191.213z"/>
      <path style="fill:#FFE079;" d="M250.947,293.827h-34.133h-25.6H157.08V276.76c0-51.2,34.133-51.2,34.133-85.333V174.36v-34.133
        v-34.133c0-34.133,17.067-51.2,17.067-51.2l42.667-51.2l42.667,51.2c0,0,17.067,17.067,17.067,51.2v34.133v34.133v17.067
        c0,34.133,34.133,34.133,34.133,85.333v17.067H310.68H250.947z"/>
      <path style="fill:#AAB1BA;" d="M250.947,106.093c14.507,0,25.6,11.093,25.6,25.6s-11.093,25.6-25.6,25.6
        c-14.507,0-25.6-11.093-25.6-25.6S236.44,106.093,250.947,106.093"/>
    </g>
    <path style="fill:#51565F;" d="M465.28,503.893H38.613c-19.627,0-38.4-18.773-38.4-38.4c0-2.56,1.707-4.267,4.267-4.267h460.8
      c2.56,0,4.267,1.707,4.267,4.267c0,2.56-1.707,4.267-4.267,4.267H8.747c2.56,13.653,16.213,25.6,29.867,25.6H465.28
      c14.507,0,29.867-15.36,29.867-29.867v-29.867h-12.8c-2.56,0-4.267-1.707-4.267-4.267v-256c0-14.507-15.36-29.867-29.867-29.867
      h-102.4c-2.56,0-4.267-1.707-4.267-4.267s1.707-4.267,4.267-4.267h102.4c19.627,0,38.4,18.773,38.4,38.4v251.733h12.8
      c2.56,0,4.267,1.707,4.267,4.267v34.133C503.68,485.12,484.907,503.893,465.28,503.893z M448.213,435.627H55.68
      c-2.56,0-4.267-1.707-4.267-4.267V192.427c0-11.947,19.627-21.333,29.867-21.333h76.8c2.56,0,4.267,1.707,4.267,4.267
      c0,2.56-1.707,4.267-4.267,4.267h-76.8c-7.68,0-21.333,7.68-21.333,12.8v234.667h384V192.427c0-5.973-6.827-12.8-12.8-12.8h-85.333
      c-2.56,0-4.267-1.707-4.267-4.267c0-2.56,1.707-4.267,4.267-4.267h85.333c11.093,0,21.333,10.24,21.333,21.333V431.36
      C452.48,433.92,450.773,435.627,448.213,435.627z M21.547,435.627c-2.56,0-4.267-1.707-4.267-4.267v-256
      c0-19.627,18.773-38.4,38.4-38.4h102.4c2.56,0,4.267,1.707,4.267,4.267s-1.707,4.267-4.267,4.267H55.68
      c-14.507,0-29.867,15.36-29.867,29.867v256C25.813,433.92,24.107,435.627,21.547,435.627z M397.013,401.493
      c-2.56,0-4.267-1.707-4.267-4.267c0-16.213-13.653-29.867-29.867-29.867c-5.12,0-10.24,1.707-15.36,4.267
      c-0.853,0.853-2.56,0.853-4.267,0c-1.707-0.853-2.56-1.707-2.56-3.413c-1.707-10.24-10.24-17.92-21.333-17.92
      s-20.48,8.533-21.333,19.627c0,1.707-1.707,3.413-3.413,3.413c-1.707,0-3.413,0-4.267-1.707
      c-8.533-13.653-23.04-21.333-39.253-21.333s-30.72,7.68-39.253,21.333c-0.853,1.707-2.56,2.56-4.267,1.707
      c-1.707-0.853-3.413-1.707-3.413-3.413c-0.853-11.093-10.24-19.627-21.333-19.627c-10.24,0-19.627,7.68-21.333,17.92
      c0,1.707-0.853,2.56-2.56,3.413c-0.853,0.853-2.56,0.853-4.267,0c-5.12-2.56-10.24-4.267-15.36-4.267
      c-16.213,0-29.867,13.653-29.867,29.867c0,2.56-1.707,4.267-4.267,4.267s-4.267-1.707-4.267-4.267c0-21.333,17.067-38.4,38.4-38.4
      c5.12,0,10.24,0.853,14.507,2.56c4.267-11.947,15.36-19.627,28.16-19.627c11.947,0,23.04,7.68,27.307,17.92
      c10.24-11.093,24.747-17.92,40.96-17.92c15.36,0,30.72,6.827,40.96,17.92c4.267-11.093,15.36-17.92,27.307-17.92
      c12.8,0,23.893,8.533,28.16,19.627c4.267-1.707,9.387-2.56,14.507-2.56c21.333,0,38.4,17.067,38.4,38.4
      C401.28,399.787,399.573,401.493,397.013,401.493z M311.68,316.16c-2.56,0-4.267-1.707-4.267-4.267v-12.8H286.08
      c-2.56,0-4.267-1.707-4.267-4.267c0-2.56,1.707-4.267,4.267-4.267h55.467v-12.8c0-26.453-9.387-38.4-18.773-49.493
      c-7.68-9.387-15.36-18.773-15.36-35.84v-85.333c0-31.573-15.36-47.787-16.213-47.787L251.947,11.52l-39.253,46.933
      c0,0.853-16.213,17.067-16.213,48.64v85.333c0,17.067-7.68,26.453-15.36,35.84c-9.387,11.093-18.773,23.04-18.773,49.493v12.8
      h55.467c2.56,0,4.267,1.707,4.267,4.267c0,2.56-1.707,4.267-4.267,4.267H196.48v12.8c0,2.56-1.707,4.267-4.267,4.267
      c-2.56,0-4.267-1.707-4.267-4.267v-12.8H158.08c-2.56,0-4.267-1.707-4.267-4.267V277.76c0-29.867,11.093-43.52,20.48-54.613
      c7.68-9.387,13.653-16.213,13.653-30.72v-85.333c0-35.84,17.92-53.76,17.92-54.613l42.667-51.2c1.707-1.707,5.12-1.707,6.827,0
      l42.667,51.2c0.853,0.853,17.92,18.773,17.92,53.76v85.333c0,13.653,5.973,21.333,13.653,30.72
      c9.387,11.947,20.48,25.6,20.48,54.613v17.067c0,2.56-1.707,4.267-4.267,4.267h-29.867v12.8
      C315.947,314.453,314.24,316.16,311.68,316.16z M251.947,316.16c-2.56,0-4.267-1.707-4.267-4.267v-68.267
      c0-2.56,1.707-4.267,4.267-4.267c2.56,0,4.267,1.707,4.267,4.267v68.267C256.213,314.453,254.507,316.16,251.947,316.16z
      M251.947,162.56c-16.213,0-29.867-13.653-29.867-29.867s13.653-29.867,29.867-29.867s29.867,13.653,29.867,29.867
      S268.16,162.56,251.947,162.56z M251.947,111.36c-11.947,0-21.333,9.387-21.333,21.333c0,11.947,9.387,21.333,21.333,21.333
      c11.947,0,21.333-9.387,21.333-21.333C273.28,120.747,263.893,111.36,251.947,111.36z"/>
  </svg>
</template>

<script>

export default {
  name: 'Roket'
}

</script>

<style lang="scss" scoped>
</style>
