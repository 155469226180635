// import Config from '@/config/app-config.js'
import Vue from 'vue'
// import '@mdi/font/css/materialdesignicons.css'
import Vuetify from 'vuetify/lib'
// import colors from 'vuetify/lib/util/colors'
import Balcony from '@/common/components/custom-icons/balcony'
import IlFlag from '@/common/components/custom-icons/il-flag'
import UsFlag from '@/common/components/custom-icons/us-flag'
import Views from '@/common/components/custom-icons/views'
import Networking from '@/common/components/custom-icons/networking'
import Roket from '@/common/components/custom-icons/roket'
import MapMarker from '@/common/components/custom-icons/map-marker'
// import accessibility from '@/common/components/custom-icons/accessibility'

Vue.use(Vuetify)

export default (config) => {
  const vuetify = new Vuetify({
    rtl: config.locale.dir === 'rtl',
    theme: {
      themes: {
        light: {
          primary: '#f9c802',
          accent: '#6d94c7',
          secondary: '#0c0c0c',
          bright: '#fbfbf9'
        }
      },
      options: {
        customProperties: true // export all vuetify colors as variables for css usage (like: color: var(--v-primary-base);)
      }
    },
    icons: {
      iconfont: 'mdi', // || 'mdiSvg' || 'md' || 'fa' || 'fa4',
      values: {
        balcony: {
          component: Balcony
        },
        ilFlag: {
          component: IlFlag
        },
        views: {
          component: Views
        },
        roket: {
          component: Roket
        },
        mapMarker: {
          component: MapMarker
        },
        networking: {
          component: Networking
        },
        usFlag: {
          component: UsFlag
        } /*,
        accessibility: {
          component: accessibility
        } */
      }
    }
  })

  return vuetify
}
