<template>
  <svg height="800px" width="800px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 508 508" xml:space="preserve">
    <circle style="fill:#F1543F;" cx="254" cy="254" r="254"/>
    <path style="fill:#2B3B4E;" d="M303.6,270.4c0,0.4,0,0.8,0,1.2L292.8,260c-13.2-14.4-14.8-36-2.8-52l12.8-17.2
      c16-21.6,47.6-22,64.4-1.2C330.4,198.4,303.6,231.6,303.6,270.4z"/>
    <circle style="fill:#324A5E;" cx="387.2" cy="270.8" r="83.6"/>
    <circle style="fill:#84DBFF;" cx="387.2" cy="270.8" r="61.6"/>
    <path style="fill:#54C0EB;" d="M418.4,270.4c0,17.2-14,31.2-31.2,31.2s-31.2-14-31.2-31.2c0-1.6,0-3.2,0.4-4.8
      c4.4-0.8,10-4.8,15.2-10.4c4.4-5.2,7.6-10.8,8.4-15.2c2.4-0.4,4.8-0.8,7.6-0.8C404.4,239.2,418.4,253.2,418.4,270.4z"/>
    <ellipse transform="matrix(-0.749 -0.6625 0.6625 -0.749 471.7935 677.1264)" style="fill:#FFFFFF;" cx="364.144" cy="249.206" rx="9.6" ry="21.199"/>
    <g>
      <path style="fill:#324A5E;" d="M238.4,216l-12.8,8.8c-1.2-6-3.6-11.6-7.2-16.8l-12.8-17.2c-1.6-2.4-3.6-4.4-5.2-6l21.6-4.4
        c-0.4,2-0.4,4-0.4,6.4C220.8,199.6,228,210.4,238.4,216z"/>
      <path style="fill:#324A5E;" d="M308,184.8c-2,1.6-3.6,3.6-5.2,6L290,208c-3.6,5.2-6,10.8-7.2,16.8L270,216
        c10.4-5.6,17.2-16.4,17.2-29.2c0-2,0-4.4-0.4-6.4L308,184.8z"/>
    </g>
    <path style="fill:#2B3B4E;" d="M215.2,260l-10.8,11.6c0-0.4,0-0.8,0-1.2c0-39.2-26.8-72-63.2-81.2c16.4-20.4,48.4-20,64.4,1.2
      l12.8,17.2C230,224,228.4,245.6,215.2,260z"/>
    <path style="fill:#324A5E;" d="M204.4,270.4c0,0.4,0,0.8,0,1.2c-0.4,45.6-37.6,82.4-83.6,82.4s-83.2-37.2-83.2-83.6
      c0-46,37.2-83.6,83.6-83.6c6.8,0,13.6,0.8,20,2.4C177.6,198.4,204.4,231.6,204.4,270.4z"/>
    <circle style="fill:#84DBFF;" cx="120.8" cy="270.8" r="61.6"/>
    <path style="fill:#54C0EB;" d="M152.4,270.4c0,17.2-14,31.2-31.2,31.2S90,287.6,90,270.4s14-31.2,31.2-31.2c2.8,0,5.2,0.4,7.6,0.8
      c0.8,4.4,3.6,10,8.4,15.2c4.8,5.6,10.8,9.2,15.2,10.4C152,267.2,152.4,268.8,152.4,270.4z"/>
    <g>
      <ellipse transform="matrix(-0.749 0.6625 -0.6625 -0.749 416.721 339.879)" style="fill:#FFFFFF;" cx="143.988" cy="248.866" rx="9.6" ry="21.199"/>
    <circle style="fill:#FFFFFF;" cx="254" cy="186.8" r="33.2"/>
    </g>
    <circle style="fill:#E6E9EE;" cx="254" cy="186.8" r="19.6"/>
  </svg>
</template>

<script>

export default {
  name: 'Views'
}

</script>

<style lang="scss" scoped>
</style>
