import axios from 'axios'
// import store from '@/app/store'

// var protocol = process.env.NODE_ENV === 'development' ? 'http' : 'https'
var baseURL = '/api'
// var baseURL = process.env.NODE_ENV === 'development' ? 'http://local.rbns:8090/api' : '/api'

const HTTP = axios.create({
  // baseURL: `${protocol}://gorbns.com/api`,
  baseURL,
  headers: {
    'Content-Type': 'application/json'
  }
  // ,
  // paramsSerializer (params) {
  //   return decodeURI(params)
  // }
})

HTTP.interceptors.response.use((response) => {
  return response
}, (error) => {
  // Do something with response error
  if (error.response.status === 401) {
    console.error('unauthorized')
  }
  return Promise.reject(error.response)
})

const get = (route, params) => {
  // console.log(params)
  return HTTP.get(route, { params }).then(res => res.data)
}

const post = (route, params, formData = false) => {
  let headers = null
  if (formData) {
    headers = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
  }

  return HTTP.post(route, params, headers).then(res => res.data)
}

const put = (route, params) => {
  return HTTP.put(route, params).then(res => res.data)
}

const Delete = (route, params) => {
  return HTTP.delete(route, params).then(res => res.data)
}

export const postRecaptchaToken = (token) => {
  if (process.env.NODE_ENV === 'development') {
    return { data: { score: 0.9 } }
  }
  return axios.post('/recaptcha', { token })
}

export const Api = {
  get,
  post,
  put,
  delete: Delete
}
