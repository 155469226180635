import Vue from 'vue'
import VueMarkdown from 'vue-markdown-v2' // npm package - https://www.npmjs.com/package/vue-markdown-v2
import Logo from './logo'
import Spacer from './spacer'
// import ScrollJumper from './scroll-to-jumper'

Vue.component('Logo', Logo)
Vue.component('Spacer', Spacer)
Vue.component('vue-markdown', VueMarkdown)
// Vue.component('scroll-to-jumper', ScrollJumper)
