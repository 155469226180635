<template>
 <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="660" height="480" viewBox="0 0 220 160"><defs><path id="a" stroke="#0038b8" stroke-width="5.5" fill-opacity="0" d="M0-29.141l-25.237 43.712h50.474z"/></defs><path fill="#fff" d="M0 0h220v160H0z"/><path fill="#0038b8" d="M0 15h220v25H0zM0 120h220v25H0z"/><use xlink:href="#a" transform="translate(110 80)"/><use xlink:href="#a" transform="rotate(180 55 40)"/></svg>
</template>

<script>
export default {

  name: 'IlFlag',

  data () {
    return {

    }
  }
}
</script>

<style lang="scss" scoped>
</style>
