import Vue from 'vue'

var filter = (text, length, clamp) => {
  if (!text) return ''

  clamp = clamp || '...'
  // var node = document.createElement('div')
  // node.innerHTML = text
  // var content = node.textContent
  return text.length > length ? text.slice(0, length) + clamp : text
}

Vue.filter('truncate', filter)
