<template>
  <svg height="800px" width="800px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 504 504" xml:space="preserve">
    <circle style="fill:#90DFAA;" cx="252" cy="252" r="252"/>
    <polygon style="fill:#FFFFFF;" points="343.1,239.6 160.9,239.6 119.6,391.4 384.4,391.4 "/>
    <g>
      <path style="fill:#CED5E0;" d="M308.4,258.5c-1.9,0.2-3.6,1.2-5.5,1.2c-1.1,0-2.2-0.2-3.4-0.4c-4.6-0.9-9.3-1.4-14-1.4
        c-1.2,0-2.5,0-3.6,0.5c-0.9,0.4-1.6,1.1-2.6,1.4c-1.9,0.6-4.2-0.4-6.3-0.3c-3.9,0.1-7.1,3.7-11,3c-0.6-2.2-3.5-3.6-6-3.2
        c-0.7,0.1-1.5,0.4-2.1,0.7c-0.9,0.3-1.7,0.7-2.6,1c-1.6,0.6-3.3,1.3-4.1,2.8c-0.4,0.8-0.5,1.7,0,2.5c1.1,1.5,3.4,1.1,5,0.3
        c1.4-0.8,2.8-1.8,4.4-2c0.6,2-1.3,4.1-3.6,4.8c-2.3,0.7-4.8,0.3-7.1-0.1c0.7,1.2-0.3,2.7-1.7,3.2s-3,0.3-4.4,0.1l-0.4,2.9
        c-2.8,0.1-5.1,2.9-4.4,5.4c1.9,0.9,4.2,1.1,6.3,0.5c-0.9-1.4-0.2-3.5,1.5-4.1c0.9-0.3,1.8-0.3,2.8-0.4c2.5-0.1,4.9-0.6,7.2-1.5
        l0.8,2.6c2.1,0.7,4.5,1,6.7,0.9v1.8l5,1.3c0.1,0.5,0.2,0.6,0.3,1.2c-4.7,3.5-11.6-0.3-17.4,1c-2.3,0.5-4.6,1.9-7,1.5
        c-1-0.2-1.9-0.7-2.9-0.8c-2.8-0.3-4.9,2.5-6,5c-0.8,2-1.5,4.1-1.3,6.2s1.3,4.3,3.4,5.2c3.7,1.7,9.4-0.7,11.8,2.6
        c2.3,7.5,3.8,15.7,4.2,24.2c0.1,2.3,0.2,4.7,1.4,6.7c1.3,2.1,3.6,3.3,5.9,3.4c1.8,0.1,3.8-0.5,4.9-2c0.6-0.8,0.9-1.8,1.2-2.8
        c0.8-2.5,1.6-5,2.4-7.5c0.3-0.9,0.6-1.9,1.2-2.6c0.6-0.8,1.5-1.3,2.1-2.1c2.4-3.2-1-8.2,0.4-11.9c0.7-2,2.6-3.2,4-4.6
        s2.4-3.8,1.2-5.4c-0.5-0.7-1.2-1.1-1.9-1.5c-4.2-2.8-7.3-7.1-8.3-11.6c1.2-0.7,2.9,0.1,3.7,1.2c0.9,1.1,1.3,2.5,2.3,3.6
        c0.7,0.7,1.5,1.2,2.4,1.7c1.8,1.1,3.9,2.1,6,1.5c1.3-0.4,2.2-1.5,1.9-2.8c-0.4-1.6-2.3-2.2-3.9-2.9c-1.5-0.6-3.1-2.2-2.3-3.4
        c4.3,0.9,8.5,2.2,12.7,3.8c2.9,2.5,5.9,5,7.8,8.4c0.5,0.8,0.9,1.7,1.6,2.3c0.7,0.6,1.9,0.9,2.5,0.3c-0.2-1.4-0.3-2.9-0.5-4.3
        c2.7-0.2,4.7-2.8,4.2-5.4c2.9,0.1,5.8,2.1,6.9,4.8c0.1,0.1,0.2,0.2,0.3,0.2c4.4,3.7,9.6-1.5,5.5-5.4l-0.1-0.1
        c2.1,0.1,4.4,0.2,6-0.9c0.6-0.5,1.1-1.2,1.1-2c0.1-1.9-1.7-3-2.9-4.4c-1.5-1.5-2.2-3.5-2.1-5.3c2.4-0.2,4.9-0.2,7.5,0
        c-2.1-2-0.7-5.2-2.5-7.4c-0.7-0.8-1.8-1.4-2.6-2.1c-0.8-0.7-1.5-1.9-0.9-2.6l8.7-1.2c0.3,1,0.5,2.1,1.3,3c0.7,0.9,2.1,1.6,3.1,1.4
        c-2-2.2,1.5-5-1.4-7.1c-0.7-0.5-1.6-0.8-2.5-0.9c-3.3-0.2-6.4,0.4-9.7-0.1C315.6,259.9,311.9,258,308.4,258.5z"/>
      <path style="fill:#CED5E0;" d="M187.4,259.7c-2.2,0-4.5,0.5-6.8,1.1c-1.9,0.5-3.8,1-5.7,1.5c-1,0.3-2,0.5-2.9,1.1
        c-0.8,0.6-1.4,1.6-1,2.3c2.9,0.7,6.1,0.9,9.3,0.7c0.7,1.5-0.2,3.5-1.3,5.1c-1.2,1.6-2.7,3.2-3.2,5.1c-0.3,1.1-0.3,2.2-0.2,3.2
        c0.2,1.9,0.5,3.8,1,5.7c0.7,2.6,1.8,5.2,3.7,7.3c0.6,0.7,1.3,1.2,1.8,2c0.3,0.6,0.5,1.2,1,1.6c0.6,0.4,1.4,0.3,2.2,0.4
        c2.2,0.3,3.4,2.3,4.8,4c1.3,1.7,3.7,3.1,5.9,1.9c0.7,3.1-2.4,6.4-2.5,9.7c-0.1,4,4,6.3,5.4,10c0.5,1.4,0.7,2.9,0.7,4.5
        c0.6,10-0.7,21.4,4.3,30.4c1.7-0.6,2.6-2.9,2.7-4.8c0.1-1.9-0.4-3.7-0.3-5.5c0.3-4,3.4-7.3,6.2-10.2c1.2-1.2,2.5-2.4,3.7-3.7
        c-0.4-2,1.5-3.9,3.1-5.3c3.4-3.1,6-7,7.7-11.1c0.3-0.7,0.5-1.4,0.5-2.1c-0.1-1.5-1.4-2.5-2.6-3.3c-5.9-3.8-11.8-7.3-17.9-10.3
        c-0.1-2.5-3.4-4-5.8-2.5c-1.6,1-2.8,3-4.7,3s-2.5-2.2-2.8-3.9c-0.3-1.7-1.5-3.7-3.4-3.1c1.3-1,1-3.1-0.4-3.5
        c-1.4-0.5-3.3,0.5-4.1,1.8c-0.8-1.8-0.8-4,0-5.9c3.3-1.2,6.8-1.7,10.2-1.5c-0.2,1.2-0.3,2.3-0.5,3.5c2.9,0.1,2.6-5.2,5.3-6.2
        c0.9-0.3,1.9-0.1,2.8-0.5c1.5-0.7,1.8-2.8,3.3-3.5c0.6-0.3,1.2-0.2,1.8-0.4c1.5-0.4,2.5-1.7,3.6-2.7c1.8-1.7,4.3-3,5.6-5
        s0.6-5.1-1.9-5.1c-2.2,0-3.8,2.4-6,2.8c-2.1,0.3-3.8-1.9-2.6-3.6c0.4-0.6,1-1,1.3-1.6c0.9-1.9-1.7-3.3-4-3.2
        c-2.3,0.1-4.8,0.8-6.6-0.2c-0.7-0.4-1.2-1.1-2.1-1.2c-1.4-0.3-2.8,0.8-4.3,1.1C190.3,260,188.8,259.8,187.4,259.7z"/>
      <path style="fill:#CED5E0;" d="M323.5,311.4c0.2,1.1,0.8,2.3,1.5,3.3c1.6-0.4,1.5-0.4,3.2-0.8c0-0.1,0-0.2,0-0.4
        c0-0.8,0.6-1.5,1.4-1.5s1.6,0.8,2,1.6c0.4,0.8,0.6,1.7,0.9,2.5c1,2.1,3.5,4,3.1,6.1c-0.1,0.5-0.4,0.9-0.4,1.4
        c0,0.8,0.6,1.5,1.1,2.2c1.5,2.3,1.4,5.7-0.9,6.3c-0.4,0.1-0.9,0.1-1.2,0.4c-0.6,0.6,0.1,1.7,0.3,2.6c0.6,2.6-2.5,4-4.9,3
        c-2.4-1.1-4.1-3.5-5.5-5.8c-0.4-0.6-0.7-1.2-1.3-1.5c-0.7-0.4-1.6-0.3-2.3-0.2c-1.7,0.3-3.4,0.6-5.1,1c-1.9,0.4-4.2,0.6-5.8-0.9
        c-0.8-0.7-1.3-1.7-1.7-2.7c-0.6-1.2-1.1-2.5-1-3.8c0.1-1.2,0.8-2.1,1.6-2.8c1.4-1.3,3.3-2.1,4.3-3.7c0.3-0.6,0.6-1.3,1.2-1.4
        c0.5-0.1,1.1,0.1,1.5-0.1c0.5-0.2,0.7-0.8,1.1-1.2c0.6-0.6,1.5-0.7,2.3-1c0.7-0.3,1.3-0.9,1.1-1.6c0-0.2,0-0.3,0.2-0.4
        c0.9-0.3,1.9-0.7,2.8-1C323.2,310.9,323.4,311.1,323.5,311.4z"/>
    </g>
    <path style="fill:#FF7058;" d="M341.9,151c0,49.7-89.9,148.8-89.9,148.8s-89.9-99.1-89.9-148.8s40.3-89.9,89.9-89.9
      S341.9,101.3,341.9,151z"/>
    <circle style="fill:#324A5E;" cx="252" cy="143.1" r="47.1"/>
  </svg>
</template>

<script>

export default {
  name: 'MpaMarker'
}

</script>

<style lang="scss" scoped>
</style>
