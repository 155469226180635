// http://numeraljs.com/
import Vue from 'vue'
import numeral from 'numeral'

numeral.register('locale', 'he', {
  delimiters: {
    thousands: ',',
    decimal: '.'
  },
  abbreviations: {
    thousand: ' א׳ ',
    million: ' מ׳ ',
    billion: ' ביל׳ ',
    trillion: ' טריל׳ '
  },
  ordinal (number) {
    return ''
  },
  currency: {
    symbol: '₪'
  }
})

Vue.prototype.setNomeralLocal = (locale) => {
  numeral.locale(locale)
}

const abbreviate = value => numeral(value).format('0.0a') // 1,200,000 -> 1.2M

const bytes = value => numeral(value).format('0 b')

const exposedNumeral = (value, format) => numeral(value).format(format)

const exponential = value => numeral(value).format('0.[00]e+0')

const ordinal = value => numeral(value).format('Oo')

const percentage = value => numeral(value).format('0.[00]%')

const separator = value => numeral(value).format('0,0')

const currency = value => numeral(value).format('$0,0')

const currencyAbbreviate = value => {
  if (value > 999999) return numeral(value).format('0.00a$')
  return currency(value)
}

Vue.filter('abbreviate', abbreviate)
Vue.filter('bytes', bytes)
Vue.filter('exponential', exponential)
Vue.filter('numeral', exposedNumeral)
Vue.filter('ordinal', ordinal)
Vue.filter('percentage', percentage)
Vue.filter('separator', separator)
Vue.filter('currency', currency)
Vue.filter('currencyAbbreviate', currencyAbbreviate)
