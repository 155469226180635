import { Loader } from '@googlemaps/js-api-loader'

export default (config) => {
  const loader = new Loader({
    apiKey: config.googleApiKey,
    language: config.locale.lang,
    version: 'weekly',
    libraries: ['places']
  })

  return loader
}
