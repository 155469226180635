// filter to create array of numbers with gap between each number according to a range of numbers
// get length of number wanted and options
// options are - gaps, min (when to start from), addPlus (adding + in the end of the last number in the output)
// EX: numbersArray(5, {gaps: 2, min: 3, addPlus: true}) => [1,3,5,7,9+]

import Vue from 'vue'

const numbersArray = (length, options) => {
  var gaps = options.gaps || 1
  var min = options.min - 1 || 0
  var addPlus = options.addPlus || false
  var arr = Array.from({ length: length - min }, (v, k) => {
    return ((k + 1) * gaps) + min
  })
  if (addPlus) {
    arr.push(`${arr[arr.length - 1] + gaps}+`)
  }
  return arr
}

Vue.filter('numbersArray', (value, options = {}) => {
  if (!value) return []
  return numbersArray(value, options)
})
