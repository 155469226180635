<template>
  <svg height="800px" width="800px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 508 508" xml:space="preserve">
    <circle style="fill:#84DBFF;" cx="254" cy="254" r="254"/>
    <g>
      <path style="fill:#324A5E;" d="M359.2,274.4l-120.4,67.2c-2.8,1.6-4,5.2-2.4,8c1.2,2,3.2,3.2,5.2,3.2c0.8,0,2-0.4,2.8-0.8
        l120.4-67.2c2.8-1.6,4-5.2,2.4-8C365.6,274,362,272.8,359.2,274.4z"/>
      <path style="fill:#324A5E;" d="M130.4,251.6c-2.4-2.4-6-2.4-8.4,0s-2.4,6,0,8.4l82.8,82c1.2,1.2,2.8,1.6,4,1.6
        c1.6,0,3.2-0.4,4.4-1.6c2.4-2.4,2.4-6,0-8.4L130.4,251.6z"/>
      <path style="fill:#324A5E;" d="M369.6,255.2l-116-69.2c-0.4-0.4-1.2-0.4-1.6-0.8l94.4-63.2c2.8-2,3.6-5.6,1.6-8.4s-5.6-3.6-8.4-1.6
        l-98.8,66.4c-2.8,2-3.6,5.6-1.6,8.4c1.2,1.6,3.2,2.8,4.8,2.8c0.4,0,0.4,0,0.8,0c-0.4,0.8-0.4,1.6-0.4,2.4c-0.8-1.6-2.8-2.8-4.8-2.8
        c-3.2,0-6,2.4-6.4,5.6l-10.4,161.6c-0.4,3.2,2.4,6,5.6,6.4h0.4c3.2,0,5.6-2.4,6-5.6l10.4-161.6c0-0.4,0-0.8,0-0.8
        c0.4,0.8,1.2,1.6,2,2l116,69.6c0.8,0.4,2,0.8,3.2,0.8c2,0,4-1.2,5.2-2.8C373.6,260.8,372.8,257.2,369.6,255.2z"/>
    </g>
    <g>
      <circle style="fill:#FFFFFF;" cx="244" cy="183.6" r="42.4"/>
      <circle style="fill:#FFFFFF;" cx="231.6" cy="356.4" r="64.4"/>
    </g>
    <circle style="fill:#FF7058;" cx="231.6" cy="356.4" r="48"/>
    <g>
      <circle style="fill:#FFFFFF;" cx="342.8" cy="116.8" r="29.2"/>
      <circle style="fill:#FFFFFF;" cx="372.4" cy="264.8" r="47.6"/>
      <circle style="fill:#FFFFFF;" cx="122" cy="251.2" r="34.4"/>
    </g>
    <circle style="fill:#FFD05B;" cx="372.4" cy="264.8" r="34.4"/>
    <circle style="fill:#E6E9EE;" cx="342.8" cy="116.8" r="18.4"/>
    <circle style="fill:#F1543F;" cx="122" cy="251.2" r="22"/>
    <circle style="fill:#54C0EB;" cx="244" cy="183.6" r="29.2"/>
  </svg>
</template>

<script>

export default {
  name: 'Networking'
}

</script>

<style lang="scss" scoped>
</style>
