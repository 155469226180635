import Vue from 'vue'

const link = (phone, text) => {
  phone = /^\+/.test(phone) ? phone : `+972${phone}`
  return `https://wa.me/${phone}?text=${text}`
}

Vue.filter('whatsapp', (phone, text) => {
  return link(phone, text)
})
